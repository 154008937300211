import App from './App';
import "./styles/index.scss"
import "./styles/text_hover.scss"
import "./styles/_underline-goes-right.scss"
import { Help } from './components/Help';
import Rules from './components/Rules';
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/faq",
        element: <Help />
    },
    {
        path: "/rules",
        element: <Rules />
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);