import React from 'react'
import image from "../assets/images/greeting.jpg"

const Greeting = () => {
    return (
        <div className='App__greeting content-block'>
            <div className="App__greeting_content">
                <div className="App__greting_text-block"><h1 className='App__greeting_heading h1 super-bold '>URAM - Шеринг самокатов №1 в Узбекистане</h1>
                    <p className="App__greeting_sub-text h5">
                        Мы развиваем микромобильность в Узбекистане и помогаем городам с инфраструктурой.
                    </p></div>
                <div className="App__greeting_image-block"><img src={image} alt="" /></div>
            </div>
        </div>
    )
}

export default Greeting