import React from 'react'
import logo from "../assets/images/logo.svg"
import "../styles/index.scss"
const Footer = () => {
  return (
    <div className='App__footer'>
      <div className="App__footer_grid">
        <div className="App__footer_company">
          <img src={logo} alt="" />
          <p>OOO "SMART SCOOTERS"</p>

        </div>
        <div className="App__footer_contacts">
          <h1 className="h5 super-bold">Контакты</h1>
          <ul>
            <li><a href="tel:+998951152200">+998951152200</a></li></ul>
        </div>
        <div className="App__foter_documents">
          <h1 className='h5 super-bold'>Документы</h1>
          <ul>
            <a href="https://uram.ddns.net/uram-privacy-policy/">  <li>Политика конфеденциальности</li></a>
            <a href="https://uram.ddns.net/uram-terms-of-user/"><li>Пользовательское соглашение</li></a>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer