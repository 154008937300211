import React from 'react'
import iphone from "../assets/images/iphone_store.png"
import android from "../assets/images/android_store.png"
import screen from "../assets/images/screen-portrait.png"
import logo from "../assets/images/favicon.svg"
import { IconContext } from "react-icons";
import { FaApple } from "react-icons/fa";
import { FaAndroid } from "react-icons/fa";

const Demonstration = () => {
    const demo = [{
        title: "Найдите",
        description: 'Найдите самокат на карте в приложении URAM',
        image: '/images/phone_1.png'
    }, {
        title: "Просканируйте",
        description: 'Далее отсканируйте QR код, чтобы разблокировать самокат.',
        image: "/images/phone_2.png"
    }, {
        title: "Поездка",
        description: 'Следуйте ПДД и наслаждайтесь поездкой',
        image:'/images/phone_3.jpg'
        // image: "https://img.li.me/content/uploads/RIDE_2022-04-19-201703_ctuw.png?auto=compress&crop=focalpoint&fit=crop&fp-x=0.4833&fp-y=0.1209&h=560&q=80&w=800&s=d33dcb0111446e9981b3f575c5f46124"
    }]
    const [number, setNumber] = React.useState(1)
    return (
        <div className='App__demonstration'>
            <div className="App__demonstration_content">
                <div className="App__demonstration_show">
                    <h3 className="App__demonstration_heading super-bold display-6  h3">Как начать поездку</h3>

                    <div className="App__demonstration_list">
                        {demo.map((card, index) => (
                            <div className="card-block">
                                <div className="background">
                                    <img src={card.image} alt="" />
                                </div>
                                <div className='flex align-center card-block__text'>
                                    <h1 className='h5'>{card.title}</h1>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>




                    <p className="App__demonstration_text h2 super-bold">Еще нету нашего приложения?</p>
                    <div className="App__demonstration_buttons">
                        <div className="button-group flex align-center">
                            <div className="button-group_logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="button-group_text">
                                <h1 className='h3 super-bold'>URAM</h1>
                                <p>Приложение для iOS и Android</p>
                            </div>
                            <div className="button-group_buttons flex align-center">
                                <a href="https://apps.apple.com/kg/app/uram/id1574174505"><IconContext.Provider value={{ size: '2.65rem' }}><FaApple /></IconContext.Provider></a>
                                <a href="https://play.google.com/store/apps/details?id=com.rental_technology.uram"><IconContext.Provider value={{ size: '2.65rem' }}><FaAndroid /></IconContext.Provider></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
{/* <a href="https://apps.apple.com/kg/app/uram/id1574174505"><img src={iphone} alt="Download on the App Store" /></a>  */ }
//    <a href="https://play.google.com/store/apps/details?id=com.rental_technology.uram"> <img src={android} alt="Get it on Google Play" /></a>
export default Demonstration