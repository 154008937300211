import React, { useState, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Button from "react-bootstrap/Button"
export const Help = ({help}) => {
  const faqList = [{
    question: "Со скольки лет можно пользоваться сервисом?",
    answer: "Пользоваться сервисом можно с 16 лет."
  }, {
    question: "Нужны ли какие-то проверки данных перед использованием сервиса?",
    answer: "Пользоваться можно уже сразу после привязки банковской карты."
  },
  {
    question: "Есть ли какие-то ограничения по весу для катания на самокате?",
    answer: "Да. Максимально допустимый вес - 100 кг."
  }, {
    question: "Куда поставить самокат после заверешения использования?",
    answer: "Самокат необходимо будет вернуть в стойку проката. Вы несете ответственность пропажу самоката."
  }, {
    question: "Что делать, если я нанес повреждения самокату?",
    answer: "Необходимо обратиться в службу технической поддержки"
  },
  ]
  return (
    <div className='App__faq content-block'>
      <h1 ref={help} className="App__faq_heading h1">FAQ</h1>
      <div className="App__faq_content">
        <Accordion className='App__faq_list' defaultActiveKey="0">
          {faqList.map((faq, index) => (

            <Accordion.Item eventKey={index}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>
                {faq.answer}
              </Accordion.Body>
            </Accordion.Item>

          ))}
        </Accordion>
      </div>
    </div>
  )
}

