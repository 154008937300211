import { useState, useEffect, useRef } from "react";
import Advantages from "./components/Advantages";
import Demonstration from "./components/Demonstration";
import Greeting from "./components/Greeting";
import Header from "./components/Header"
import Rules from "./components/Rules";
import Footer from "./components/Footer";
import "./styles/animations.scss"
import { Help } from "./components/Help";
import Logo from "./components/Logo";
import Video from "./components/Video"
import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import SmoothScrolling from "./components/SmoothScrolling";
import Cities from "./components/Cities";
import { Scrollbar } from 'react-scrollbars-custom';
function App() {
  const rulesRef = useRef(null)
  const helpRef = useRef(null)
  const feedbackRef = useRef(null)
  const [scrollY, setScrollY] = useState(0);
  const [animate, setAnimate] = useState(false)
  const [show, setShow] = useState(false)
  const [move, setMove] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 2000);
    setTimeout(() => {
      setMove(true)
    }, 2400);
  }, [])
  useEffect(() => {
    document.documentElement.classList.add('lenis-scrolling')
  })
  useEffect(() => {
    if (animate === false) {
      document.body.classList.remove('body-after')
      document.body.classList.add('body-before');
    }
    else {
      setTimeout(() => {
        document.body.classList.add('body-after')
        document.body.classList.remove('body-before')
        setShow(true)
      }, 200);
    }
  }, [animate])
  const scrollRules = () => (
    rulesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  )

  const scrollFeedback = () => (
    feedbackRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  )
  const scrollHelp = () => (
    helpRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  )

  return (
    <>
      <SmoothScrolling/>
           <div id="#home" className="App">
        {animate ? (<div style={show ? { opacity: '1' } : { opacity: '0' }} >
          <Header help={scrollHelp} feedback={scrollFeedback} rules={scrollRules} />
          <div className="App__container">
            <div className="container">
            <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Greeting /></div>
            <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Advantages /></div>
            </div>
            {/* <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Cities /></div> */}
            
            <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Video /></div>
            <div ref={rulesRef} className={`animate-elem ${move ? 'show' : 'hide'}`}><Rules /></div>
            <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Demonstration /></div>
            <div className={`animate-elem ${move ? 'show' : 'hide'}`}><Help help={helpRef} /></div>
            <div ref={feedbackRef} className={`animate-elem ${move ? 'show' : 'hide'}`}><Footer /></div>
          </div>
          </div>
        ) : <Logo />}

      </div>
      </>
  );
}

export default App;
