import React from 'react'
import image_1 from "../assets/images/block-image_1.png"
import { FaMountainCity } from "react-icons/fa6";
import { RiSmartphoneFill } from "react-icons/ri";
import { RiSmartphoneLine } from "react-icons/ri";
import { IoPricetag, IoPricetagSharp } from "react-icons/io5";
import { IoPricetagsSharp } from "react-icons/io5";
import { IconContext } from "react-icons";
const Advantages = () => {
    return (
        <div className='App__advantages'>
            <div className="App__advantages__wrapper ">
                <div className="App__advantages_content mini-block content-block">
                    <div className="App__davantages_icon"><IconContext.Provider value={{ size:'2.65rem' }}><FaMountainCity/></IconContext.Provider></div>
                    <h3 className="App__advantages_heading h3 super-bold">Ташкент и Бухара</h3>
                    <p className="App__advantages_text h6">Насладитесь красотами Ташкента и Бахары, проезжая на наших самокатах.</p>
                </div>
                <div className="App__advantages_content mini-block content-block">
                <div className="App__davantages_icon"><IconContext.Provider value={{ size:'2.65rem' }}><RiSmartphoneFill/></IconContext.Provider></div>
                    <h3 className="App__advantages_heading h3 super-bold">Приложение</h3>
                    <p className="App__advantages_text h6">Всего за пару кликов, вы сможете найти самокат и реализовывать ваши мечты, катаясь по всему городу.</p>
                </div>
                <div className="App__advantages_content mini-block content-block">
                <div className="App__davantages_icon"><IconContext.Provider value={{ size:'2.65rem' }}><IoPricetagsSharp/></IconContext.Provider></div>
                    <h3 className="App__advantages_heading h3 super-bold">Тарифы</h3>
                    <p className="App__advantages_text h6">Мы предлагаем тарифы по демократичной цене, у нас несколько тарифов, которые подойдут под ваши разные нужды.</p>
                </div>
            </div></div>
    )
}

export default Advantages