import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button"
import rule_1 from "../assets/images/rule_1.jpg"
const Rules = () => {
  const [rule, setRule] = useState(1)
  const [animateRule, setAnimateRule] = useState(null)
  const rules = [
    { title: "Один человек — один самокат", description: "Кататься вдвоем опасно. Самокат может не выдержать веса двух людей, а управлять им будет сложнее.", number: '1' },
    { title: "Лучше надень шлем", description: "Особенно, если только учишься и еще не уверен в своих навыках.", number: '2' },
    { title: "Переходить дорогу нужно пешком", description: "Даже если не видишь машин. Пройди пешком несколько метров, а самокат как раз отдохнет!", number: '3' },
    { title: "Если выпил — не бери самокат", description: "Кататься нужно трезвым. Даже если кажется, что море по колено — лучше пройдись пешком или вызови такси.", number: '4' },
    { title: "Транспорт 16+", description: "Кататься на самокате могут только лица, достигшие 16 лет.", number: '5' },
    { title: 'Заряженный смартфон', description: "Арендуйте самокат с заряженным смартфоном.", number: '6' },
    { title: "Соблюдайте порядок", description: 'Не бросайте самокаты на тротуаре и не мешайте пешеходам.', number: '7' },
    { title: "И конечно же, не забудьте пристегнуть самокат", description: "После того, как завершили поездку,пристегните самокат цепью и закроте самокат", number: '8' }]
  const nextSlide = () => {
    if (rule !== rules.length) {
      setRule(rule + 1)
    }
    else if (rule === rules.length) {
      setRule(1)
    }
  }



  const prevSlide = () => {
    if (rule !== 1) {
      setRule(rule - 1)
    }
    else if (rule === 1) {
      setRule(rules.length)
    }
  }
  return (
    <div className='App__rules content-block'>
      <div className="App__rules_content container">


        <div className="App__rules">
          <h3 className="App__rules_heading super-bold display-6 h3">Правила дорожного движения</h3>
          <p className="App__rules_text h5">С 1 марта 2023 года электросамокаты получили официальный статус в ПДД – теперь это средство индивидуальной мобильности (СИМ)</p>
          <div className='flex pdd-cards'>
            <div className="pdd-card">
              <img src="https://thumb.tildacdn.com/tild3035-3330-4737-a662-393663366334/-/resize/158x/-/format/webp/1.png" alt="" />
              <p>Знак 3.35. Движение на средствах индивидуальной мобильности запрещено</p>
            </div>
            <div className="pdd-card">
              <img src="https://thumb.tildacdn.com/tild3762-3765-4661-b631-333930313237/-/resize/266x/-/format/webp/image2023_upscayl_8x.jpg" alt="" />
              <p>Знак 8.4.7.2. Вид транспортного средства</p>
            </div>
            <div className="pdd-card">
              <img src="https://thumb.tildacdn.com/tild3137-6239-4032-b330-646466316461/-/resize/193x/-/format/webp/image2022_upscayl_8x.jpg" alt="" />
              <p>Знак 8.4.16. Кроме вида транспортного средства</p>
            </div>
          </div>
          <div>

            {/* {rules.map((rule, index) => (
              <>
                <h1>{rule.title}</h1>
                <p>{rule.description}</p>
                </>
            ))} */}
          </div>
        </div>



      </div>
    </div>
  )
}

export default Rules