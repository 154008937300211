import React, {useRef} from 'react'
import logo from "../assets/images/logo.svg"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ProgressBar from "react-scroll-progress-bar";

const Header = ({ rules, help, feedback, start }) => {
  const startRef = useRef(null)
  const scrollStart = () => (
    startRef.current?.scrollIntoView({ behavior: 'smooth'})
  )
  return (
    <div ref={startRef} >
      <Navbar fixed='top' expand="lg" className="bg-body-tertiary mb-30 padding-none">
      <ProgressBar bgcolor="#000" height="6px"  duration="0.2" />
        <Container className="App__header align-center">
          <Navbar.Brand href="/"><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className='align-center justify-between adaptive-flex-bar' id="basic-navbar-nav ">
            <Nav className="App__header_list adaptive-flex-list flex">
              <Nav.Link onClick={scrollStart}> <p className="h5">Главная</p> </Nav.Link>
              <Nav.Link onClick={help}> <p className="h5">Помощь</p> </Nav.Link>
              {/* <Nav.Link href="/rules"> <p className="h5">ПДД</p> </Nav.Link> */}
              <Nav.Link onClick={feedback}> <p className="h5">Связаться с нами</p> </Nav.Link>
              
            </Nav>
            <Nav className='App__header_list adaptive-flex-list flex'>
            <Nav.Link href="https://apps.apple.com/kg/app/uram/id1574174505"><button className='App__header_button button'>Скачайте наше приложение</button></Nav.Link>
            </Nav>
          
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header